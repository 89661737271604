<template>
  <div id="navigation-bar">
    <nav id="nav-bar">
      <div class="home-button">
        <h3>
          <router-link to="/">豆瓣直租</router-link>
        </h3>
      </div>
      <div class="sub-sites">
        <ul>
          <li v-for="item in cities" :key="item.id">
            <a href="#" @click="$emit('city', item.name)" :class="[{ nav_filter_clicked: item.clicked }]">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="miscellaneous">
        <ul>
          <li>
            <router-link to="/help">使用帮助</router-link>
          </li>
          <!--
          <li>
            <router-link to="/red-packet">红包优惠</router-link>
          </li>
          <li>
            <a
              href="https://op7vjk9gsf.feishu.cn/docx/L8pudg6sDo32FtxcYLkciDwMnEf"
              target="_blank"
              >有房出租</a
            >
          </li>
          -->
          <li>
            <router-link to="/feedback">意见反馈</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref, onMounted, defineExpose } from "vue";
import util from "@/util/util";
import constant from "@/util/constant";

onMounted(() => {
  setNavigationBarResponsive();
});

// for Vue v-for loop
let idTmp = 0;

const cities = ref([]);
for (const city of constant.topCities) {
  cities.value.push({
    id: idTmp++,
    name: city,
    clicked: false,
  });
}

function setNavigationBarResponsive() {
  const element1 = document.querySelector("#nav-bar");
  const element2 = document.querySelector("#navigation-bar");
  if (util.isMobile(navigator)) {
    element1.style.setProperty("height", "auto");
    element2.style.setProperty("margin-bottom", "128px");
  } else {
    element1.style.setProperty("height", "32px");
    element2.style.setProperty("margin-bottom", "32px");
  }
}

function setCityClickStatus(city) {
  for (const item of cities.value) {
    item.clicked = item.name == city;
  }
}

defineExpose({
  setCityClickStatus,
});
</script>

<style scoped lang="scss">
@import "@/styles/global.scss";
@import "@/styles/main.scss";

#nav-bar {
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: space-around;
  align-items: center;
  // douban
  background: #545652;
  // Apple
  // background: #444;
  // background: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

#nav-bar div ul {
  display: flex;
  display: -webkit-flex; /* Safari */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 1rem;
  list-style-type: none;
  padding: 0;
}

#nav-bar div a {
  // douban
  color: #d5d5d5;
  // Apple
  // color: #f5f5f7;
  text-align: center;
  text-decoration: none;
}
</style>
